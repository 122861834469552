.app {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.info {
  padding: 0 50px;
  font-size: 18px;
}

.viewport {
  border: 1px solid rgb(0, 217, 255);
  background:linear-gradient(25deg,  #63c6f3, #add8e6);
  background: linear-gradient(25deg,#1db8ff,#c1edff);
  position: absolute;
  top: 150px;
  left: 30px;
  overflow: hidden;
}

.rect {
/*   border: 1px solid blue;
  background: lightblue; */
  position: absolute;
}

.player {
  --d: 150px;
  position: absolute;
  width: 150px;
  height: var(--d);
  margin-top: calc(-1 *  var(--d));
}

.topCloud{
  position: absolute;
  top:20px;
  right:30px;
}

.bottomCloud{
  position: absolute;
  bottom:200px;
  right:50px;
}

.mainCloud{
  position: absolute;
  top:25px;
  left:20px;
}

.mainCloudText {
  position: absolute;
  top: 90px;
  left: 20px;
  right: 30px;
  bottom: 110px;
}

.topCloudText {
  position: absolute;
  top: 50px;
  left: 70px;
  right: 60px;
  bottom: -60px;
}

.bottomCloudText {
  position: absolute;
  top: 50px;
  left: 70px;
  right: 60px;
  bottom: -60px;
}
 
p{
  font: arial;
  font-size: 12;
  color:#00628f
}